<script setup lang="ts">
import { useUserStore  } from "~/stores/user";
const userStore = useUserStore();

const { $supabaseClient } = useNuxtApp();

const isOpen = ref(false);
const route = useRoute();
const isLoaded = ref(false);

watch(() => route.path, () => {
  isOpen.value = false;
});

const stepperStatus = ref({
  agents: false,
  workflows: false,
  whatsappBusinessAccounts: false,
  whatsappTemplates: false,
  whatsappConversations: false,
})

const stepperCompletedCount = computed(() => {
  return Object.values(stepperStatus.value).filter((value) => value).length;
})

async function getWorkspaceAssets() {
  try {
    const { data, error } = await $supabaseClient
      .from("workspaces")
      .select(`
        agents (id),
        workflows (id),
        whatsapp_business_accounts (id),
        whatsapp_templates (id),
        whatsapp_conversations (id)
      `)
      .eq("id", userStore.user.workspaceId)
      .eq("workflows.is_active", true)
      .single();

    if (error) throw error;

    // console.log(data);
    stepperStatus.value = {
      agents: (data?.agents ?? []).length > 0,
      workflows: (data?.workflows ?? []).length > 0,
      whatsappBusinessAccounts: (data?.whatsapp_business_accounts ?? []).length > 0,
      whatsappTemplates: (data?.whatsapp_templates ?? []).length > 0,
      whatsappConversations: (data?.whatsapp_conversations ?? []).length > 0,
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoaded.value = true;
  }
}

onBeforeMount(async () => {
  await getWorkspaceAssets();
})

const steps = computed(() => {
  return [
    {
      label: "Set up your AI agent",
      key: "agents",
      icon: "i-lucide-bot",
      description: "Train an AI agent with your website content or business documents to answer questions about your business",
      backgroundColor: "primary",
      buttonText: "Create AI Agent",
      href: `/agents${stepperStatus.value.agents ? '' : '?action=create'}`,
      defaultOpen: !stepperStatus.value.agents,
    },
    {
      label: "Create a workflow",
      key: "workflows",
      icon: "i-lucide-network",
      description: "Create a workflow to let your AI agent answer the customer questions automatically",
      backgroundColor: "indigo",
      buttonText: "Create Workflow",
      href: `/workflows${stepperStatus.value.workflows ? '' : '?action=create'}`,
      defaultOpen: stepperStatus.value.agents && !stepperStatus.value.workflows,
    },
    {
      label: "Connect your WhatsApp",
      key: "whatsappBusinessAccounts",
      icon: "i-lucide-link",
      description: "Connect your WhatsApp phone number with BlazingFast",
      backgroundColor: "green",
      buttonText: "Connect WhatsApp",
      href: "/settings/whatsapp",
      defaultOpen: stepperStatus.value.agents && stepperStatus.value.workflows && !stepperStatus.value.whatsappBusinessAccounts,
    },
    {
      label: "Send a test message to the number",
      key: "whatsappConversations",
      icon: "i-lucide-send-horizontal",
      description: "Send a test message to the connected number to make sure everything is working",
      backgroundColor: "black",
      buttonText: "Go to inbox",
      href: "/inbox",
      defaultOpen: stepperStatus.value.agents && stepperStatus.value.workflows && stepperStatus.value.whatsappBusinessAccounts && !stepperStatus.value.whatsappConversations,
    },
    {
      label: "Submit a WhatsApp template message",
      key: "whatsappTemplates",
      icon: "i-lucide-message-circle-code",
      description: "Submit a marketing or utility template message to re-initiate the conversation",
      backgroundColor: "pink",
      buttonText: "Create Template Message",
      href: `/whatsapp/templates${stepperStatus.value.whatsappTemplates ? '' : '/create'}`,
      defaultOpen: stepperStatus.value.agents && stepperStatus.value.workflows && stepperStatus.value.whatsappBusinessAccounts && stepperStatus.value.whatsappConversations && !stepperStatus.value.whatsappTemplates,
    },
  ]
})
</script>

<template>
  <NuxtButton
    v-if="userStore.user?.role === 'owner' && stepperCompletedCount < 5 && isLoaded"
    variant="solid"
    color="white"
    size="sm"
    :ui="{
      rounded: 'rounded-full',
      color: {
        white: {
          solid: 'ring-1 ring-pink-400',
        }
      }
    }"
    icon="i-lucide-list-todo"
    @click="isOpen = true"
  >
    Set up AI chatbot in 5 steps
  </NuxtButton>
  <NuxtSlideover
    v-model="isOpen"
  >
    <NuxtCard
      class="flex flex-col flex-1"
      :ui="{
        header: {
          padding: 'py-4',  
        },
        body: {
          base: 'flex-1'
        },
        ring: '',
        divide: 'divide-y divide-gray-100'
      }"
    >
      <template #header>
        <div class="flex items-center justify-between">
          <h3 class="flex items-center gap-2 text-base font-semibold leading-6 text-gray-900 dark:text-white">
            Set up your AI support agent in just 5 simple steps ({{ stepperCompletedCount }}/5)
          </h3>
          <NuxtButton 
            color="gray" 
            variant="ghost" 
            icon="i-heroicons-x-mark-20-solid" 
            class="-my-1" 
            size="lg"
            :padded="false"
            @click="isOpen = false" 
          />
        </div>
      </template>
      <div class="h-full flex flex-col gap-4">
        <NuxtAccordion
          :items="steps"
          :ui="{
            wrapper: 'gap-4',
            container: 'border border-gray-200 rounded-lg',
            item: {
              base: 'border-t border-gray-200',
              padding: 'p-0',
            }
          }"
        >
          <template #default="{ item, index, open }">
            <NuxtButton 
              color="gray" 
              variant="ghost" 
              :ui="{
                rounded: 'rounded-none',
                padding: { sm: 'p-3' }
              }"
            >
              <span class="font-medium text-gray-900 text-left">
                Step {{ index + 1 }}: {{ item.label }}
              </span>
              <NuxtIcon 
                v-if="stepperStatus[item.key as keyof typeof stepperStatus]"
                name="i-lucide-circle-check"
                class="w-4 h-4 text-green-500"
              />
              <template #trailing>
                <NuxtIcon
                  name="i-heroicons-chevron-right-20-solid"
                  class="w-5 h-5 ms-auto transform transition-transform duration-200"
                  :class="[open && 'rotate-90']"
                />
              </template>
            </NuxtButton>
          </template>
          <template #item="{ item }">
            <div class="p-3 space-y-2.5">
              <p class="text-sm text-gray-900">
                {{ item.description }}
              </p>
              <NuxtButton 
                variant="solid"
                :color="item.backgroundColor"
                :icon="item.icon"
                :to="item.href"
                size="xs"
              >
                {{ item.buttonText }}
              </NuxtButton>
            </div>
          </template>
        </NuxtAccordion>
      </div>
    </NuxtCard>
  </NuxtSlideover>
</template>

<style scoped>

</style>